// eslint-disable-next-line
import * as loginService from '@/api/login'
import store from '@/store'
// 导入icon模块
// import homeSvg from '@/assets/icons/home.svg?inline'
// const iconList = {
//   homeSvg
// }
// eslint-disable-next-line
import { BasicLayoutForManager, BlankLayout, PageView, RouteView, TabLayout } from '@/layouts'

// 前端路由表
const constantRouterComponents = {
  // 基础页面 layout 必须引入
  BasicLayout: BasicLayoutForManager,
  BlankLayout: BlankLayout,
  RouteView: RouteView,
  PageView: PageView,
  TabLayout: TabLayout,
  // 你需要动态引入的页面组件

  // 流量
  // 供应商
  Supplier: () => import(/* webpackChunkName: "flow" */'@/viewsForManage/Flow/Supplier'),
  // 媒体管理
  Adplace: () => import(/* webpackChunkName: "flow" */'@/viewsForManage/Flow/Adplace'),
  // 广告位配置
  AdplaceConfig: () => import(/* webpackChunkName: "flow" */'@/viewsForManage/Flow/AdplaceConfig'),

  // 预算管理
  // 广告源配置
  BudgetSource: () => import(/* webpackChunkName: "budget" */'@/viewsForManage/Budget/BudgetSource'),
  Dspconfiguration: () => import(/* webpackChunkName: "budget" */'@/viewsForManage/Budget/DspSourceConfig'),
  // 广告主管理
  Sonfig: () => import(/* webpackChunkName: "budget" */'@/viewsForManage/Budget/advertisement'),

  // 数据报表
  // 综合报表
  SynthReport: () => import(/* webpackChunkName: "reportdatas" */'@/viewsForManage/reportdatas/SynthReport'),
  // 小时报表
  HoursReport: () => import(/* webpackChunkName: "reportdatas" */'@/viewsForManage/reportdatas/HoursReport'),
  // 上传第三方数据
  ThirdParty: () => import(/* webpackChunkName: "reportdatas" */'@/viewsForManage/reportdatas/thirdParty'),
  // 媒体报表
  MediaReport: () => import(/* webpackChunkName: "reportdatas" */'@/viewsForManage/reportdatas/MediaReport'),
  // 预警报表
  WarningReport: () => import(/* webpackChunkName: "reportdatas" */'@/viewsForManage/reportdatas/Warning'),
  //  CPA天级报表
  DaysCPAReport1046: () => import(/* webpackChunkName: "reportdatas" */'@/viewsForManage/reportdatas/DaysCPAReport1046'),
  // CPA小时报表
  HoursCPAReport1046: () => import(/* webpackChunkName: "reportdatas" */'@/viewsForManage/reportdatas/HoursCPAReport1046'),
  // 消耗报表
  CostReport: () => import(/* webpackChunkName: "reportdatas" */'@/viewsForManage/reportdatas/CostManager'),
  // 消耗报表（超管）
  Consume: () => import(/* webpackChunkName: "reportdatas" */'@/viewsForManage/reportdatas/Consume'),

  // 高级功能
  Personnel: () => import(/* webpackChunkName: "system" */'@/viewsForManage/FunctionList/personnel'),
    // 角色权限
  Rights: () => import(/* webpackChunkName: "system" */'@/viewsForManage/FunctionList/rights'),
    // 角色权限编辑页
  Addingrolerights: () => import(/* webpackChunkName: "system" */'@/viewsForManage/FunctionList/components/addingrolerights'),
  // 素材屏蔽
  CreativeMask: () => import(/* webpackChunkName: "system" */'@/viewsForManage/FunctionList/CreativeMask'),
  // 编辑添加屏蔽规则
  EditMaskRule: () => import(/* webpackChunkName: "system" */'@/viewsForManage/FunctionList/EditMaskRule'),
  // 素材快照
  Sckz: () => import(/* webpackChunkName: "system" */'@/viewsForManage/FunctionList/sckz'),
  // 映射管理
  Mapping: () => import(/* webpackChunkName: "system" */'@/viewsForManage/FunctionList/Mapping'),
  // 流量策略
  FlowStrategy: () => import(/* webpackChunkName: "system" */'@/viewsForManage/FunctionList/FlowStrategy'),
  // 预警管理
  Warning: () => import(/* webpackChunkName: "system" */'@/viewsForManage/FunctionList/Warning'),
  // 添加编辑预警管理
  EditWarning: () => import(/* webpackChunkName: "system" */'@/viewsForManage/FunctionList/EditWarning'),

  // 首页
  // 媒体首页
  MediaDashboard: () => import(/* webpackChunkName: "mediadashboard" */'@/viewsForManage/mediaDashboard'),

  // 财务管理
  // 额度管理
  Capital: () => import(/* webpackChunkName: "finance" */'@/viewsForManage/Finance/CapitalManager'),
  // 申请结算
  Settle: () => import(/* webpackChunkName: "finance" */'@/viewsForManage/Finance/SettleManager'),

  // 下拉页面
  // 账户信息
  Account: () => import(/* webpackChunkName: "personal" */'@/viewsForManage/Personal/AccountInfo'),
  // 修改密码
  EditPassword: () => import(/* webpackChunkName: "personal" */'@/viewsForManage/Personal/EditPassword'),
  // 财务信息
  FinanceInfo: () => import(/* webpackChunkName: "personal" */'@/viewsForManage/Personal/financeInfo'),
  // 日志记录
  Log: () => import(/* webpackChunkName: "personal" */'@/viewsForManage/Personal/Log'),
  // apikey
  ApiKey: () => import(/* webpackChunkName: "personal" */'@/viewsForManage/Personal/ApiKey')
}

// 前端未找到页面路由（固定不用改）
const notFoundRouter = {
  path: '*',
  redirect: '/404',
  hidden: true
}

// 根级菜单
const rootRouter = {
  key: '',
  name: 'index',
  path: '',
  component: 'BasicLayout',
  redirect: '/dashboard',
  meta: {
    title: '首页'
  },
  children: []
}
/**
 * 动态生成菜单
 * @param token
 * @returns {Promise<Router>}
 */
export const generatorDynamicRouter = (token) => {
  return new Promise((resolve, reject) => {
    loginService
      .getCurrentUserNav()
      .then((res) => {
        if (res.code === 200 && res.data) {
          const tempData = res.data
          const newResult = []
          // 一级菜单排序
          const sortRoutes = tempData
            .filter((item) => item.parentId === 0)
            .sort((a, b) => {
              return a.meta.order - b.meta.order
            })
          // 一级菜单的id数组
          // const menuIdList = sortRoutes.map((item) => item.id)
          // console.log(menuIdList)
          const childRoutes = tempData.filter((item) => item.parentId !== 0)
          const result = [...sortRoutes, ...childRoutes, ...newResult]
          // 存所有的权限路由 名称
          const localStorageMeuns = result.map((item) => item.name)
          store.commit('SET_ALLROUTERS', localStorageMeuns)
          // icon处理
          // result.forEach(item => {
          //   if (item.parentId === 0) {
          //     item.meta.icon = iconList[item.meta.icon]
          //   }
          // })
          // 当前页总路由数据
          const menuNav = []
          // 总路由中的子路由列表
          const childrenNav = []
          // 后端数据, 根级树数组,  根级 PID
          listToTree(result, childrenNav, 0)
          // 主菜单中的子路由设置
          rootRouter.children = childrenNav
          // 设置主路由信息
          menuNav.push(rootRouter)
          // 通过主路由设置路由列表
          // 树形结构
          const routers = generator(menuNav)
          // 再将404路由加入
          routers.push(notFoundRouter)
          routers.length !== 0 &&
            routers[0].children.forEach((item) => {
              if (item.children) {
                // 二级路由排序，再重定向，防止重定向到不是menu的第一项
                item.children.sort((a, b) => {
                  return a.meta.order - b.meta.order
                })
                // 二级路由动态重定向
                item.redirect =
                  item.children &&
                  item.children.length !== 0 &&
                  `/${item.name}/${item.children && item.children.length !== 0 && item.children[0].name}`
              }
            })
          resolve(routers)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * 格式化树形结构数据 生成 vue-router 层级路由表
 *
 * @param routerMap
 * @param parent
 * @returns {*}
 */
const generator = (routerMap, parent) => {
  return routerMap.map((item) => {
    const { title, show, hideChildren, hiddenHeaderContent, target, icon, order, type, hiddenTab } = item.meta || {}
    const currentRouter = {
      // 如果路由设置了 path，则作为默认 path，否则 路由地址 动态拼接生成如 /dashboard/workplace
      path: item.path || `${(parent && parent.path) || ''}/${item.key}`,
      // 路由名称，建议唯一
      name: item.name || item.key || '',
      // 该路由对应页面的 组件 :方案1
      // component: constantRouterComponents[item.component || item.key],
      // 该路由对应页面的 组件 :方案2 (动态加载)
      component: ((item.isTabView && +item.parentId === 0) ? TabLayout : undefined) || constantRouterComponents[item.component || item.key] || (() => import(`@/viewsForManage/${item.component}`)),

      // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      meta: {
        title: title,
        icon: icon || undefined,
        hiddenHeaderContent: hiddenHeaderContent,
        target: target,
        permission: item.name,
        order: order,
        type,
        hiddenTab
      }
    }
    // 是否设置了隐藏菜单
    currentRouter.hidden = !show
    // 是否设置了隐藏子菜单
    if (hideChildren) {
      currentRouter.hideChildrenInMenu = true
    }
    // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
    if (!currentRouter.path.startsWith('http')) {
      currentRouter.path = currentRouter.path.replace('//', '/')
    }
    // 重定向
    item.redirect && (currentRouter.redirect = item.redirect)
    // 是否有子菜单，并递归处理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children, currentRouter)
    }
    return currentRouter
  })
}

/**
 * 数组转树形结构
 * @param list 源数组
 * @param tree 树
 * @param parentId 父ID
 */
const listToTree = (list, tree, parentId) => {
  list.forEach((item) => {
    // 判断是否为父级菜单
    if (item.parentId === parentId) {
      const child = {
        ...item,
        key: item.key || item.name,
        children: []
      }
      // 迭代 list， 找到当前菜单相符合的所有子菜单
      listToTree(list, child.children, item.id)
      // 删掉不存在 children 值的属性
      if (child.children.length <= 0) {
        delete child.children
      }
      // 加入到树中
      tree.push(child)
    }
  })
}
