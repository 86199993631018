<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-10-08 15:24:49
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-26 19:38:45
 * @FilePath: /mediatom-web/src/viewsForManage/Finance/CapitalManager/RechargeModal/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-modal
    v-model="costWarningVisible"
    @onCancel="handleCancel"
    @ok="handleCancel"
    :closable="false"
    width="315px"
    :title="null"
    :zIndex="999999"
  >
    <div class="wrapper">
      <img :src="imgUrl" alt="">
      <h3 class="title">余额不足请充值</h3>
      <p>
        根据您账户过去7天的费用趋势分析，<span class="primary-text" style="font-weight: bold">当前余额预计可支持{{ insufficientBalanceDay }}天的广告投放。</span>一旦余额用完,将无法继续发起广告请求。为了避免影响您的平台使用体验，请及时进行充值。
      </p>
    </div>
    <div class="footer" slot="footer">
      <a-button class="btn" type="primary" @click="handleCancel">确认</a-button>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      imgUrl: require('./imgs/warning.png')
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    costWarningVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    ...mapState({
      insufficientBalance: (state) => state.user.insufficientBalance,
      insufficientBalanceDay: (state) => state.user.insufficientBalanceDay
    })
  },
  methods: {
    handleCancel () {
      this.costWarningVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  img{
    width: 92px;
    margin-bottom: 12px;
  }
  h3{
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 12px;
  }
  p{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 16px;
    line-height: 25px;
    padding: 2px;
    text-align: center;
  }
}
.footer{
  display: flex;
  justify-content: center;
  .btn{
    width: 100%!important;
  }
}
::v-deep{
  .ant-modal-wrap .ant-modal .ant-modal-footer{
    border-top: none;
    padding-top: 0;
  }
  .ant-modal-body{
    border-radius: @mediumRadius @mediumRadius 0 0;
    background: #fff;
    padding-bottom: 0;
  }
}
</style>
